// https://codepen.io/lesliesamafful/pen/oNXgmBG
import { React, useContext } from "react";
import userInfoContext from "../../context/userInfoContext";
import "./InfoCode.css";

import { BsCode } from "react-icons/bs";
import { GoGift } from "react-icons/go";
import { TbDiscount2 } from "react-icons/tb";
import { TbListNumbers } from "react-icons/tb";
import { IoMdCalendar } from 'react-icons/io'

const InfoCode = () => {
  const { userInfo, setUserInfo } = useContext(userInfoContext);

  return (
    <div style={{ margin: "0 15%" }}>
      <div className="row topBotSeparacion">
        <div className="col-6 col-md-4 separationMobile ">
          <div className="card">
            <div className="card-body">
              <div className="flex-safari ">
                <div className="media-body ">
                  <h3>{userInfo[0].code}</h3>
                  <span>Código descuento</span>
                </div>
                <div className="align-self-center">
                  <BsCode className="icons" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-4 separationMobile">
          <div className="card">
            <div className="card-body">
              <div className="flex-safari ">
                <div className="media-body ">
                  <h3>{userInfo[0].mensual_points}</h3>
                  <span>Puntos mensuales dkside</span>
                </div>
                <div className="align-self-center">
                  <IoMdCalendar className="icons" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-4 separationMobile">
          <div className="card">
            <div className="card-body">
              <div className="flex-safari ">
                <div className="media-body ">
                  <h3>{userInfo[0].total_points + userInfo[0].mensual_points}</h3>
                  <span>Puntos totales dkside</span>
                </div>
                <div className="align-self-center">
                  <GoGift className="icons" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row topBotSeparacion">
      <div className="col-6 col-md-4 separationMobile">
          <div className="card">
            <div className="card-body">
              <div className="flex-safari ">
                <div className="media-body ">
                  <h3>{userInfo[0].porcentaje}%</h3>
                  <span>de descuento</span>
                </div>
                <div className="align-self-center">
                  <TbDiscount2 className="icons" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-4 separationMobile">
          <div className="card">
            <div className="card-body">
              <div className="flex-safari ">
                <div className="media-body ">
                  <h3>Próximamente</h3>
                  <span>Veces que se ha utilizado el código este mes</span>
                </div>
                <div className="align-self-center">
                  <TbListNumbers className="icons" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCode;
