import React from "react";
import premio from "../../images/premios/EMBAJADORES.webp";
import "./Premios.css";

const Premios = () => {
  return (
    <div>
        <h1 className="text-center premios-title">Premios</h1>
      <img
        src={premio}
        className="img-fluid img-premios"
        alt="en mantenimiento"
      />
    </div>
  );
};

export default Premios;
