import { React, useState, useContext } from 'react';
import userInfoContext from '../../context/userInfoContext';
import $ from "jquery";


const Form = () => {
  const [inputs, setInputs] = useState({});
  const {userInfo, setUserInfo} = useContext(userInfoContext);

  let codigo = false;

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const validarCodigo = (event) => {
    event.preventDefault();
    if (inputs.codDescuento === null || inputs.codDescuento.length === 0 || /^\s+$/.test(inputs.codDescuento)) {
      document.getElementById("codDescuento").classList.add("is-invalid");

    } else {
      if ($("#codDescuento").hasClass("is-invalid")) {
        document.getElementById("codDescuento").classList.remove("is-invalid");
      }
      codigo = true;
    }
    
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (codigo) {
      var url = 'https://apidkside.marccervantes.com/user/points';
      var data = {"codigo": inputs.codDescuento};

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(function(response) {
        return response.json();
      })
      .then(function(data) {
        setUserInfo(data);
      })
      .catch(function(err) {
        console.error(err);
      });      
    }
  }

  if (userInfo != null && userInfo.message === "Wrong user code" && userInfo.message === "Invalid user code") {
    document.getElementById("codDescuento").classList.add("is-invalid");
  }

  return (
    <div style={{width: "50%", margin: "0 auto", marginTop: "3%", marginBottom: "5%"}}>
      <form noValidate onSubmit={handleSubmit}>
        <div className="form-row align-items-center">
          <div className="col-auto text-center">
            <label className="sr-only" htmlFor="codDescuento">
              Código descuento
            </label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">%</div>
              </div>
              <input type="text" className="form-control" id="codDescuento" name='codDescuento' placeholder="Código descuento" autoComplete="off" value={inputs.codDescuento || ""} onChange={handleChange} onBlur={validarCodigo} />
            </div>
          </div>
          <div className="col-auto text-center">
            <button type="submit" className="btn btn-primary mb-2">
              Consultar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
