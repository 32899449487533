import { React, useContext, useState } from "react";
import Form from "../Form/Form";
// import Form from "../Form/FormCash";
import userInfoContext from "../../context/userInfoContext";
import "./Home.css";

import logo from "../../images/nav/logodkside.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

// Pages
import InfoCode from "../InfoCode/InfoCode";
// import InfoDkcash from "../InfoDkcash/InfoDkcash";
import Premios from "../Premios/Premios";
// import Footer from "../Footer/Footer";

const Home = () => {
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const [consultarDatos, setConsultarDatos] = useState(true);
  const [premios, setPremios] = useState(false);

  function clickConultarCodigo() {
    setPremios(false);
    setConsultarDatos(true);
    setUserInfo(null);
  }

   function clickPremios() {
     setPremios(true);
     setConsultarDatos(false);
    setUserInfo(null);
  }

  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="150"
              className="d-inline-block align-top"
              alt=""
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* <p className="nav-link nav-p navHover"><a href="https://www.dkside.com" target="_blank" rel="noreferrer" style={{textDecoration: "none", color: "var(--bs-nav-link-color)"}}>Tienda</a></p>*/}
              <p className="nav-link nav-p navHover" onClick={clickConultarCodigo}>Consultar puntos</p>
              <p className="nav-link nav-p navHover" onClick={clickPremios}>Premios</p>
              <a className="nav-link nav-p navHover" href="/legal">Cláusula puntos dkside</a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* {consultarDatos === true && premios === false ? <Form /> : null} */}
      {consultarDatos === true ? <Form /> : null}
      {premios === true ? <Premios /> : null}
      {userInfo != null && userInfo.message !== "Wrong user code" && userInfo.message !== "Invalid user code" ? (
        <InfoCode />
        // <InfoDkcash />
      ) : null}
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
