import { React, useState } from "react";
import Home from "./pages/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import userInfoContext from "./context/userInfoContext";
import Clausula from "./pages/Clausula/Clausula";

function App() {
  const [userInfo, setUserInfo] = useState(null);

  return (
    <userInfoContext.Provider value={{ userInfo, setUserInfo }}>
      <div className="App">
        <Router basename={'/'}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/legal" element={<Clausula />} />
          </Routes>
        </Router>
      </div>
    </userInfoContext.Provider>
  );
}

export default App;
