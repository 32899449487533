import React from "react";

const Clausula = () => {
  return (
    <div>
        <div style={{maxWidth: "1111px", margin: "0 auto", marginTop: "3rem"}}>
            <a href="/" >Volver</a>
        </div>
    
        <div style={{ maxWidth: "860px", margin: "0 auto"}}>
            <h2 style={{textAlign: "center", marginBottom: "3rem"}}>Cláusula puntos dkside</h2>
        <p>
            <span style={{ fontWeight: "400" }}>CLÁUSULA 1.- PREMIOS</span>
        </p>
        <ul>
            <li>
            <span style={{ fontWeight: "400" }}>
                100 puntos = una joya para elevar el look
                <br />
            </span>
            </li>
            <li>
            <span style={{ fontWeight: "400" }}>
                300 puntos = una camiseta bien top
                <br />
            </span>
            </li>
            <li>
            <span style={{ fontWeight: "400" }}>
                500 puntos = una hoodie para lucir (Sudadera)
                <br />
            </span>
            </li>
        </ul>

        <ul>
            <li style={{ fontWeight: "400" }}>
            Los premios se pueden acumular y gastar 2 a la vez, ejemplo, si tengo
            420 puntos, puedo gastar 100 con joya y 300 para la camiseta y te
            quedarán 20 puntos para acumularlos.
            </li>
        </ul>
        <br />
        <p>
            <span style={{ fontWeight: "400" }}>CLÁUSULA 2.- PRODUCTOS</span>
        </p>
        <ul>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                Si no hay stock del producto que deseas tendrás que esperar a que
                haya re-stock o escoger otro producto con stock.
            </span>
            </li>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                Una vez elegido el producto, no hay posibilidad de cambiarlo.
            </span>
            </li>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                Al ser un regalo, una vez pasado los 14 días, no tendrá garantía.
            </span>
            </li>
        </ul>
        <br />
        <p>
            <span style={{ fontWeight: "400" }}>
            CLÁUSULA 3. - CÓMO CONSEGUIR LOS PUNTOS
            </span>
        </p>

        <ul>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                3 puntos por comentar en el último post de Instagram.
            </span>
            </li>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                3 puntos por compartir el último post de Instagram.
            </span>
            </li>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                10 puntos por hacer una reseña en Google.
            </span>
            </li>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                15 puntos por hacer un video reacción en tiktok/reels
            </span>
            </li>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                20 puntos por cada venta que se haga con tu código.
            </span>
            </li>
        </ul>

        <ul>
            <li>
            <span style={{ fontWeight: "400" }}>
                Ganarás 15 puntos si subes contenido con nuestros productos a tus
                redes sociales, como por ejemplo, TikTok, promocionando tu código de
                descuento, enseñando el pedido que te ha llegado y enseñando los
                productos.
            </span>
            </li>
        </ul>
        <ul>
            <li>
            <span style={{ fontWeight: "400" }}>
                Si subes un video a tus redes sociales de cómo te ha llegado el
                pedido y nos mandas captura después de 1 semana de que se haya
                subido te devolverá el importe del envío. Importante añadir los
                hashtags #dkside #feelthevibes
            </span>
            </li>
        </ul>
        <ul>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                Para sumar los puntos se tendrá que mandar un comprobante a la
                cuenta de instagram @dkside_ El comprobante será una foto, la foto
                del comprobante tendrá que ser con un máximo de 48h después de subir
                el post, una vez pasado las 48h ya no contarán los puntos.
            </span>
            </li>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                Repostear los posts es válido hasta el último post, una vez se suba
                un nuevo post, ya no vale el penúltimo.
            </span>
            </li>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>Los puntos son acumulables.</span>
            </li>
        </ul>
        <p>
            <span style={{ fontWeight: "400" }}>&nbsp;</span>
        </p>
        <p>
            <span style={{ fontWeight: "400" }}>CLÁUSULA 4. - ENVÍOS</span>
        </p>
        <ul>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                Los premios no están exentos de gastos de envío, que será de 6€.
            </span>
            </li>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                Si el cliente se equivoca al mandarnos los datos de envío, dkside no
                se hará cargo del envío.
            </span>
            </li>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                Si cuando llegue el pedido no hay respuesta del cliente y el
                producto llega otra vez a las oficinas de dkside, dkside no se hará
                cargo del envío.
            </span>
            </li>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                Si el pedido se pierde y no llega al destino final, dkside no se
                hará cargo del envío.
            </span>
            </li>
            <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
                Los envíos que no estén dentro de las zonas de envío de dkside, no
                podremos hacer el envío, a no ser que el cliente se quiera hacer
                cargo de la diferencia del coste o facilitar otra dirección.
            </span>
            </li>
        </ul>
        <p>
            <span style={{ fontWeight: "400" }}>
            CLÁUSULA 5. - PREMIOS EMBAJADORES
            <br />
            </span>
        </p>
        <ul>
            <li>
            <span style={{ color: "#000000" }}>
                Los embajadores tienen un 20% de descuento en el primer pedido, solo
                tenéis que avisarnos por dm.
            </span>
            </li>
            <li>
            <span style={{ color: "#000000" }}>
                Los Top 3 mejores embajadores del mes tendrán 1 sudadera gratis, 1
                camiseta gratis y una joya gratis respectivamente.
            </span>
            </li>
        </ul>
        <br />
        <p>
            <span style={{ fontWeight: "400" }}>
            dkside se reserva el derecho de modificar las bases legales y los
            puntos.
            </span>
        </p>
        </div>
    </div>
  );
};

export default Clausula;
